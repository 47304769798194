import { APP_INITIALIZER, Inject, Injectable } from '@angular/core';
import { BootstrapperBase } from '@trustedshops/tswp-core-common';
import { UserCentricsConsent } from '../core/user-centrics-consent';
import { DOCUMENT } from '@angular/common';
import { TOKENS as TOKENS_ANALYTICS, TrackerType, TrackingService } from '@trustedshops/tswp-core-analytics';
import { ENVIRONMENT, Environment } from '../models/environment.type';

@Injectable()
export class HubSpotBootstrapper extends UserCentricsConsent implements BootstrapperBase {
  public constructor(
    @Inject(TOKENS_ANALYTICS.TrackingService) private readonly _trackingService: TrackingService,
    @Inject(ENVIRONMENT) private readonly _environment: Environment,
    @Inject(DOCUMENT) readonly _document: Document,
  ) {
    super(_document);
  }

  public async initialize(): Promise<void> {
    const hasHubSpotConsent = await this.userConsentObtained('HubSpot');
    this.ensureHubSpotTrackerRegistered(hasHubSpotConsent);
  }

  private ensureHubSpotTrackerRegistered(hasHubSpotConsent: boolean): void {
    if (this._trackingService.trackers.includes(this._environment.tracking.hubspot.id)) {
      return;
    }

    if (hasHubSpotConsent) {
      this._trackingService
        .registerTracker(TrackerType.HubSpotAnalytics, {
          id: this._environment.tracking.hubspot.id,
          scriptUrl: this._environment.tracking.hubspot.scriptUrl
        });
    }
  }
}

export const HubSpotInitializer = [
  HubSpotBootstrapper,
  {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: (initializer: HubSpotBootstrapper) => {
      return () => initializer.initialize();
    },
    deps: [HubSpotBootstrapper]
  }
];
